import React, { FC } from "react";
import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";

interface ICustomBtnProps {
  text?: string;
  isPostcard?: boolean;
  onClick?: () => void;
  width?: string;
  marginRight?: string;
  marginBottom?: string;
}

const BreadCrumbButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText("#fec53f"),
  backgroundColor: "#fec53f",
  '&:hover': {
    backgroundColor: "#ffd15c",
  },
  borderRadius: "10px",
  fontSize: "16px",
  fontWeight: 600,
  padding: "6px 28px",
  fontFamily: "Commissioner",
  textTransform: "none",
}));

const PostCardButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText("#fec53f"),
  backgroundColor: "#fec53f",
  '&:hover': {
    backgroundColor: "#ffd15c",
  },
  borderRadius: "10px",
  fontSize: "17px",
  fontWeight: 600,
  padding: "8px 105px",
  fontFamily: "Commissioner",
  textTransform: "none",
}));

export const CustomBtn: FC<ICustomBtnProps> = ({
  text = "text",
  isPostcard,
  onClick,
  width= "600px",
  marginRight= "0",
  marginBottom= "0",
}) => {
  return (!isPostcard ? (
      <BreadCrumbButton
        variant="contained"
        onClick={onClick}
      >
        {text}
      </BreadCrumbButton>
    ) : (
      <PostCardButton
        style={{ width: width, marginRight: marginRight, marginBottom: marginBottom }}
        variant="contained"
        onClick={onClick}
      >
        {text}
      </PostCardButton>
    )
  );
};
