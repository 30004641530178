import React, {FC, useEffect, useState} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import "../HolidaysList.css";

const API_BASE: string | undefined = process.env.REACT_APP_API_BASE_URL;

interface IHolidaySlideProps {
  name: string;
  title_image: string;
}

export const HolidaySlide: FC<IHolidaySlideProps> = ({
  name,
  title_image
}) => {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`${API_BASE}/${title_image}`).then((res) => res.status === 200 ? setLoading(false) : setLoading(true))
  }, [title_image]);

  return (
    <>
      {isLoading ? (
        <CircularProgress sx={{color: "#FFD25C"}} size={60}/>
      ) : (
        <>
          <img
            itemProp="image"
            className="holidaysImage"
            src={`${API_BASE}/${title_image}`}
            alt="Изображение праздника"
            loading="lazy"
            onLoad={() => setLoading(false)}
          />
          <span
            itemProp="name"
            content={name}
            className="holidaysImageDescr"
          >
            {name}
          </span>
        </>
      )}
    </>
  );
};
