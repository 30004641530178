import { combineReducers } from "redux";
import user from "./user.reducer";
import holidays from "./holidays.reducer";

const mainReducer = combineReducers({
  user,
  holidays,
});

type MainReducerType = typeof mainReducer;
export type AppStateType = ReturnType<MainReducerType>;

export default mainReducer;
