import {
  GET_HOLIDAY_FAILURE,
  GET_HOLIDAY_MEDIA_FAILURE,
  GET_HOLIDAY_MEDIA_SUCCESS,
  GET_HOLIDAY_SUCCESS,
  GET_HOLIDAYS_LIST_FAILURE,
  GET_HOLIDAYS_LIST_SORT_FAILURE,
  GET_HOLIDAYS_LIST_SORT_SUCCESS,
  GET_HOLIDAYS_LIST_SUCCESS,
  GET_FIRST_HOLIDAY_MEDIA_FAILURE,
  GET_SECOND_HOLIDAY_MEDIA_FAILURE,
  GET_SECOND_HOLIDAY_MEDIA_SUCCESS,
  GET_THIRD_HOLIDAY_MEDIA_FAILURE,
  GET_THIRD_HOLIDAY_MEDIA_SUCCESS,
  GET_FIRST_HOLIDAY_MEDIA_SUCCESS,
  GET_FOURTH_HOLIDAY_MEDIA_SUCCESS,
  GET_FOURTH_HOLIDAY_MEDIA_FAILURE,
} from "../constants/actionTypes";
import {
  HolidayMediaFileType,
  HolidaySortType,
  HolidaysSortType,
  HolidayType,
} from "app/types";
import { HolidaysActionsTypes } from "../actions/holidays.actions";

export type InitialHolidaysType = {
  error: string | undefined | null;
  holidaysList:  HolidaySortType[];
  holidaysSortList:  HolidaysSortType[];
  holidayFilesMediaList: HolidayMediaFileType[];
  firstHolidayFilesMediaList: HolidayMediaFileType[];
  secondHolidayFilesMediaList: HolidayMediaFileType[];
  thirdHolidayFilesMediaList: HolidayMediaFileType[];
  fourthHolidayFilesMediaList: HolidayMediaFileType[];
  isHolidaysFetched: boolean;
  isHolidaysListFetched: boolean;
  isHolidaysMediaFetched: boolean;
  isHolidaysListSortFetched: boolean;
  holidayData: HolidayType | null;
};

const initialState: InitialHolidaysType = {
  error: null,
  holidaysList: [],
  holidaysSortList: [],
  holidayFilesMediaList: [],
  firstHolidayFilesMediaList: [],
  secondHolidayFilesMediaList: [],
  thirdHolidayFilesMediaList: [],
  fourthHolidayFilesMediaList: [],
  isHolidaysFetched: false,
  isHolidaysListFetched: false,
  isHolidaysMediaFetched: false,
  isHolidaysListSortFetched: false,
  holidayData: null,
};

const holidays = (
  state: InitialHolidaysType = initialState,
  action: HolidaysActionsTypes
): InitialHolidaysType => {
  switch (action.type) {
// Логика получения списка праздников с сортировкой по месяцам
    case GET_HOLIDAYS_LIST_SORT_SUCCESS:
      return {
        ...state,
        isHolidaysListSortFetched: true,
        holidaysSortList: action.payload.holidaysSortList.holidays,
        error: null
      };
    case GET_HOLIDAYS_LIST_SORT_FAILURE:
      return {
        ...state,
        isHolidaysListSortFetched: true,
        holidaysSortList: [],
        error: action.payload.error
      };
// Логика получения списка праздников без сортировки
    case GET_HOLIDAYS_LIST_SUCCESS:
      return {
        ...state,
        isHolidaysListFetched: true,
        holidaysList: action.payload.holidaysList.holidays,
        error: null
      };
    case GET_HOLIDAYS_LIST_FAILURE:
      return {
        ...state,
        isHolidaysListFetched: true,
        holidaysList: [],
        error: action.payload.error
      };
// Логика получения медиа файлов праздника
    case GET_HOLIDAY_MEDIA_SUCCESS:
      return {
        ...state,
        isHolidaysMediaFetched: true,
        holidayFilesMediaList: action.payload.holidayFilesMediaList.media_files,
        error: null
      };
    case GET_HOLIDAY_MEDIA_FAILURE:
      return {
        ...state,
        isHolidaysMediaFetched: true,
        holidayFilesMediaList: [],
        error: action.payload.error
      };
    case GET_FIRST_HOLIDAY_MEDIA_SUCCESS:
      return {
        ...state,
        isHolidaysMediaFetched: true,
        firstHolidayFilesMediaList: action.payload.firstHolidayFilesMediaList.media_files,
        error: null
      };
    case GET_FIRST_HOLIDAY_MEDIA_FAILURE:
      return {
        ...state,
        isHolidaysMediaFetched: true,
        firstHolidayFilesMediaList: [],
        error: action.payload.error
      };
    case GET_SECOND_HOLIDAY_MEDIA_SUCCESS:
      return {
        ...state,
        isHolidaysMediaFetched: true,
        secondHolidayFilesMediaList: action.payload.secondHolidayFilesMediaList.media_files,
        error: null
      };
    case GET_SECOND_HOLIDAY_MEDIA_FAILURE:
      return {
        ...state,
        isHolidaysMediaFetched: true,
        secondHolidayFilesMediaList: [],
        error: action.payload.error
      };
    case GET_THIRD_HOLIDAY_MEDIA_SUCCESS:
      return {
        ...state,
        isHolidaysMediaFetched: true,
        thirdHolidayFilesMediaList: action.payload.thirdHolidayFilesMediaList.media_files,
        error: null
      };
    case GET_THIRD_HOLIDAY_MEDIA_FAILURE:
      return {
        ...state,
        isHolidaysMediaFetched: true,
        thirdHolidayFilesMediaList: [],
        error: action.payload.error
      };
    case GET_FOURTH_HOLIDAY_MEDIA_SUCCESS:
      return {
        ...state,
        isHolidaysMediaFetched: true,
        fourthHolidayFilesMediaList: action.payload.fourthHolidayFilesMediaList.media_files,
        error: null
      };
    case GET_FOURTH_HOLIDAY_MEDIA_FAILURE:
      return {
        ...state,
        isHolidaysMediaFetched: true,
        fourthHolidayFilesMediaList: [],
        error: action.payload.error
      };
    // Логика получения конкретного праздника
    case GET_HOLIDAY_SUCCESS:
      return {
        ...state,
        isHolidaysFetched: true,
        holidayData: action.payload.holidayData,
        error: null
      };
    case GET_HOLIDAY_FAILURE:
      return {
        ...state,
        isHolidaysFetched: true,
        holidayData: null,
        error: action.payload?.error
      };
    default:
      return state;
  }
};

export default holidays;
