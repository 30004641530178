import React, { FC, JSX } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, NavigateFunction, Link, useLocation } from "react-router-dom";
import { CustomBtn } from "../CustomBtn/CustomBtn";
import { AppDispatch } from "../../store/store";
import {getHolidayFailure, getHolidaysMediaFailure} from "../../actions/holidays.actions";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import "./BreadCrumbs.css";

interface IBreadCrumbsProps {
  holidayName?: string;
  isPostcard?: boolean;
  className?: string;
  onClick?: () => void;
}

export const BreadCrumbs: FC<IBreadCrumbsProps> = ({
  holidayName,
  isPostcard,
  className,
  onClick,
}) => {
  const navigate: NavigateFunction = useNavigate();

  const dispatch = useDispatch<AppDispatch>();

  const pathname: string = useLocation().pathname;

  const goToMainPage = (): void => {
    navigate("/");
    dispatch(getHolidayFailure());
    dispatch(getHolidaysMediaFailure());
  };

  const breadcrumbs: JSX.Element[] = [
    <Link
      itemProp="item"
      key="1"
      to={"/"}
      className="mainPageLink"
      onClick={goToMainPage}
    >
      <>
        <span itemProp="name">
          Поздравлятор
        </span>
        <meta itemProp="position" content="1"/>
      </>
    </Link>,
    <Link
      itemProp="item"
      key="2"
      to={`${isPostcard ? `/holiday/${pathname?.split("/")?.[2]}` : ""}`}
      className={`${isPostcard ? "holidayActiveLink" : "holidayLink"}`}
      onClick={isPostcard ? () => {} : () => {}}
    >
      <>
        <span itemProp="name">
          {holidayName}
        </span>
        <meta itemProp="position" content="2"/>
      </>
    </Link>,
    <span
      itemProp="item"
      key="3"
      className={`${isPostcard ? "holidayPostcardLink" : "holidayPostcardLinkHidden"}`}
    >
      <>
        <span itemProp="name">
          Открытка
        </span>
        <meta itemProp="position" content="3"/>
      </>
    </span>,
  ];

  return (
    <div className={`breadCrumbs ${className}`}>
      <Breadcrumbs
        itemProp="itemListElement"
        itemType="http://schema.org/ListItem"
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {isPostcard
          ? breadcrumbs
          : breadcrumbs?.slice(0, breadcrumbs?.length - 1)
        }
      </Breadcrumbs>
      <CustomBtn
        text="Поделиться"
        onClick={onClick}
      />
    </div>
  );
};
