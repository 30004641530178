import "./index.css";
import React, {
  JSX,
  useRef,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "./reducers/mainReducer";
import { AppDispatch } from "./store/store";
import { Route, Routes } from "react-router-dom";
import { HolidaysList } from "./components/HolidaysList/HolidaysList";
import { HolidayCard } from "./components/HolidayCard/HolidayCard";
import { HolidayPostcard } from "./components/HolidayPostcard/HolidayPostcard";
import { HolidaysNavigation } from "./components/HolidaysNavigation/HolidaysNavigation";
import { getHolidaysListData, getHolidaysSortList } from "./actions/holidays.actions";
import CircularProgress from "@mui/material/CircularProgress";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import AppBanner from "smart-app-banner-react";
import NotFound from "./utils/NotFound/NotFound";

const appBannerInfo = {
  imageUrl: "https://appimg-drru.dbankcdn.ru/application/icon144/10169/00803fea3a23433aab1404b8673682db.png",
  name: <span className="text-[13px]">Поздравлятор. Открытки и стихи</span>,
  publisher: <span className="text-[12px]">Поздравлятор</span>,
  linkButtonText: <span className="text-blue-400 text-[12px]">Просмотреть</span>,
  linkUrl: "https://play.google.com/store/apps/details?id=ru.pozdrav.android"
};

function App(): JSX.Element {
  const [navHeight, setNavHeight] = useState<number>(0);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isHolidayOpened, setIsHolidayOpened] = useState<boolean>(false);
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [isMobile, setMobile] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = (): void => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return (): void => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth > 950) setMobile(false);
    if (windowWidth <= 950) setMobile(true);
  }, [windowWidth]);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getHolidaysSortList());
    dispatch(getHolidaysListData());
  }, []);

  useEffect(() => {
    if (isHolidayOpened) {
      setIsHolidayOpened(false);
    }
  }, [isHolidayOpened]);

  const {
    holidaysList,
    holidaysSortList,
  } = useSelector((state: AppStateType) => state.holidays);

  useEffect(() => {
    if (holidaysSortList?.length && holidaysList?.length) {
      setLoading(true);
    } else {
      setTimeout(() => dispatch(getHolidaysSortList()), 500);
      setTimeout(() => dispatch(getHolidaysListData()), 500);
    }
  }, [holidaysSortList, holidaysSortList, isLoading]);

  const updateNavHeight = useCallback(() => {
    if (ref.current) {
      const navHeight: number = ref.current.clientHeight;
      setNavHeight(navHeight)
    }
  }, [ref.current]);

  useEffect(() => {
    setTimeout(() => updateNavHeight(), 300);

    const handleResize = () => {
      setTimeout(() => updateNavHeight(), 300);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [updateNavHeight, isHolidayOpened]);

  return (
    <div className="app-container relative">
      <AppBanner
        appInfo={appBannerInfo}
        position='top'
        onClose={() => {}}
        hidden={false}
        style={{
          transform: `translateY(${isMobile ? "0" : "-100px"})`,
          transition: "transform 0.5s ease",
          zIndex: 100,
          width: "100%",
          boxShadow: "none",
        }}
      />
      <Header
        isMenuOpen={isMenuOpen}
        setMenuOpen={setMenuOpen}
      />
      {!isLoading ? (
        <div className="flex items-center justify-center h-[500px]">
          <CircularProgress sx={{ color: "#FFD25C" }} size={100} />
        </div>
      ) : (
        <>
          {isMobile && (
            <HolidaysNavigation
              isMobile={isMobile}
              isMenuOpen={isMenuOpen}
              navHeight={navHeight}
              setMenuOpen={setMenuOpen}
              setIsHolidayOpened={setIsHolidayOpened}
            />
          )}
          <div className="flex overflow-hidden">
            {!isMobile && (
              <HolidaysNavigation
                isMobile={isMobile}
                isMenuOpen={isMenuOpen}
                navHeight={navHeight}
                setMenuOpen={setMenuOpen}
                setIsHolidayOpened={setIsHolidayOpened}
              />
            )}
            <main className="w-full">
              <Routes>
                <Route path="/" element={<HolidaysList holidayListRef={ref}/>}/>
                <Route path="/holiday/:id" element={<HolidayCard holidayCardRef={ref}/>}/>
                <Route path="/holiday/:id/postcard/:id" element={<HolidayPostcard holidayPostCardRef={ref}/>}/>
                <Route element={<NotFound />} />
              </Routes>
            </main>
          </div>
        </>
      )}
      <Footer/>
    </div>
  );
}

export default App;
