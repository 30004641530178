import React from "react";

const NotFound = () => {
  return (
    <div>
      <h2>404 - Страница не найдена</h2>
      <p>Извините, запрашиваемая страница не существует.</p>
    </div>
  );
};

export default NotFound;
