import React, {
  FC,
  Dispatch,
  useState,
  useEffect,
  SetStateAction,
} from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import { getHolidaysSortList } from "../../actions/holidays.actions";
import { HolidaysNavigationList } from "./HolidaysNavigationList/HolidaysNavigationList";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import "./HolidaysNavigation.css";

interface IHolidaysNavigationProps {
  navHeight: number;
  isMobile: boolean;
  isMenuOpen: boolean;
  setMenuOpen: Dispatch<SetStateAction<boolean>>;
  setIsHolidayOpened: Dispatch<SetStateAction<boolean>>;
}

const TextFieldStyle = styled(TextField)({
  '& .MuiInputBase-input': {
    padding: 9,
    backgroundColor: '#F2F2EA',
    borderRadius: "10px",
    '&::placeholder': {
      color: '#000',
      fontSize: "15px",
    }
  },
  '& .MuiInputBase-adornedStart': {
    backgroundColor: '#F2F2EA',
    borderRadius: "10px",
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#F2F2EA',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#F2F2EA',
    },
    '&:hover fieldset': {
      borderColor: '#F2F2EA',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#F2F2EA',
    },
  },
});

export const HolidaysNavigation: FC<IHolidaysNavigationProps> = ({
  navHeight,
  isMobile,
  isMenuOpen,
  setMenuOpen,
  setIsHolidayOpened
}) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>('');

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (!searchValue) dispatch(getHolidaysSortList());
  }, [searchValue]);

  useEffect(() => {
    const delay: number = 500;

    const timeoutId: NodeJS.Timeout = setTimeout(() => {
      dispatch(getHolidaysSortList(debouncedSearchTerm));
    }, delay);

    return () => clearTimeout(timeoutId);
  }, [debouncedSearchTerm]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value }: { value: string } = event.target;
    setSearchValue(value);
  };

  useEffect(() => {
    const delay: number = 500;
    const timeoutId: NodeJS.Timeout = setTimeout(() => {
      setDebouncedSearchTerm(searchValue);
    }, delay);

    return () => clearTimeout(timeoutId);
  }, [searchValue]);

  return (
    <div className={`${isMobile ? (isMenuOpen ? "navigationMobileWrapOpen" : "navigationMobileWrapHidden") : "navigationWrap"} `}>
      <div className={isMobile ? "navigationHeaderWrap" : "w-[90%]"}>
        <h1 className="navigationHeader">
          Праздники
        </h1>
        <div itemType="http://schema.org/WebSite" className="navigationSearch mb-[38px]">
          <TextFieldStyle
            itemProp="potentialAction"
            itemType="http://schema.org/SearchActio"
            type="search"
            placeholder="Поиск"
            className="w-full navigationSearchField"
            value={searchValue}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon className="navigationSearchIcon"/>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
      <HolidaysNavigationList
        isMobile={isMobile}
        navHeight={navHeight}
        setMenuOpen={setMenuOpen}
        setIsHolidayOpened={setIsHolidayOpened}
      />
    </div>
  );
};
