import React, {Dispatch, FC, JSX, SetStateAction, useState} from "react";
import { ReactComponent as LogoSvg } from "../../assets/icons/logo.svg";
import { ReactComponent as BurgerMenuSvg } from "../../assets/icons/burger_menu_icon.svg";
import { ReactComponent as CloseMenuSvg } from "../../assets/icons/close_menu_icon.svg";
import { useNavigate, NavigateFunction, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import { getHolidayFailure, getHolidaysMediaFailure } from "../../actions/holidays.actions";
import "./Header.css";

interface IHeaderProps {
  isMenuOpen: boolean;
  setMenuOpen: Dispatch<SetStateAction<boolean>>;
}

const Header: FC<IHeaderProps> = ({
  isMenuOpen,
  setMenuOpen,
}): JSX.Element => {
  const navigate: NavigateFunction = useNavigate();

  const dispatch = useDispatch<AppDispatch>();

  const goToMainPage = (): void => {
    navigate("/");
    dispatch(getHolidayFailure());
    dispatch(getHolidaysMediaFailure());
  };

  return (
    <header className="headerWrap bg-white">
      <Link
        itemProp="url"
        to={"/"}
        className="flex items-center w-[190px] cursor-pointer"
        onClick={goToMainPage}
      >
        <LogoSvg className="headerLogo" />
        <span className="fs-20px">Поздравлятор</span>
      </Link>
      <button
        className="headerMenuBtn"
        onClick={() => setMenuOpen(!isMenuOpen)}
      >
        {isMenuOpen ? (
          <CloseMenuSvg className="headerMenuBtn" />
        ) : (
          <BurgerMenuSvg className="headerMenuBtn" />
        )}
      </button>
    </header>
  );
};

export default Header;
