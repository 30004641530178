import React, {
  FC,
  JSX,
  Dispatch,
  useState,
  useEffect,
  useCallback,
  SetStateAction,
} from "react";
import { useSelector } from "react-redux";
import { useNavigate, NavigateFunction, useLocation, Link } from "react-router-dom";
import { HolidaySortType, HolidaysSortType } from "app/types";
import { AppStateType } from "../../../reducers/mainReducer";
import { format, parse  } from "date-fns";
import { ru } from "date-fns/locale";
import CustomScrollbar from "../../../utils/CustomScrollbar/CustomScrollbar";
import "./HolidaysNavigationList.css";

const API_BASE: string | undefined = process.env.REACT_APP_API_BASE_URL;

interface IHolidayNavigationListProps {
  isMobile: boolean;
  navHeight: number;
  setMenuOpen: Dispatch<SetStateAction<boolean>>;
  setIsHolidayOpened: Dispatch<SetStateAction<boolean>>;
}

export const HolidaysNavigationList: FC<IHolidayNavigationListProps> = ({
  isMobile,
  navHeight,
  setMenuOpen,
  setIsHolidayOpened
}) => {
  const [activeHoliday, setActiveHoliday] = useState<number>(0);

  const navigate: NavigateFunction = useNavigate();

  const pathname: string = useLocation().pathname;

  useEffect(() => {
    const holidayUrl: string[] = pathname?.split("holiday");
    const holidayId: string = holidayUrl?.[1]?.split("/")?.[1];

    if (holidayId) {
      setActiveHoliday(Number(holidayId));
    } else {
      setActiveHoliday(0);
    }
  }, [pathname]);

  const { holidaysSortList, holidayFilesMediaList } = useSelector((state: AppStateType) => state.holidays);
  const formatDate = (dateString: string): string => {
    try {
      const date: Date = parse(dateString, "dd-MM-yyyy", new Date());
      return format(date, "d MMMM", { locale: ru });
    } catch (error) {
      return "";
    }
  };

  const openHolidayCard = (holidayId: number): void => {
    navigate(`holiday/${holidayId}`);
    setActiveHoliday(holidayId);
    setIsHolidayOpened(true);
    setMenuOpen(false);
  };

  const renderHoliday = useCallback((holiday: HolidaySortType[]): JSX.Element[] => {
    return holiday?.map(({ id, name, date, title_image }: HolidaySortType, index: number) => {
      const formattedDate: string = formatDate(date);
      const formattedPrevDate: string = formatDate(holiday?.[index - 1]?.date);

      return (
        <li className="holiday-item" key={id}>
          {formattedPrevDate !== formattedDate && (
            <span className="holiday-date">
              {formattedDate}
            </span>
          )}
          <Link
            itemProp="url"
            to={`holiday/${id}`}
            className={`
              flex items-center mb-[10px] p-[15px] holiday-wrap cursor-pointer 
              ${activeHoliday === id ? "holiday-active-wrap" : ""}
            `}
            onClick={() => openHolidayCard(id)}
          >
            <div itemScope itemType="http://schema.org/ImageObject" className="w-[70px] h-[70px] mr-[15px]">
              <img
                itemProp="image"
                content={name}
                className="w-[70px] h-[70px] holiday-image-block"
                src={`${API_BASE}/${title_image}`}
                alt="Изображение праздника"
                loading="lazy"
              />
            </div>
            <span itemProp="description" content={name} className="holiday-descr">
              {name}
            </span>
          </Link>
        </li>
      )
    });
  }, [activeHoliday]);

  return(
    <CustomScrollbar>
      <nav
        itemScope itemType="http://schema.org/SiteNavigationElement"
        className="holiday-navigation"
        style={{
          height: !isMobile ? navHeight - 200 : "100%",
          transition: "height 1s ease-in-out"
        }}
      >
        {holidaysSortList?.map(({ month_name, items }: HolidaysSortType, index: number) => (
          <ul
            className={`
            ${index !== holidaysSortList?.length - 1 ? "pb-[37px] holidays-list" : ""}
            ${index !== 0 ? "mt-[37px]" : ""}
          `}
            key={index}
          >
            <li className="holiday-month">
              {month_name}
            </li>
            {renderHoliday(items)}
          </ul>
        ))}
      </nav>
      <div className="gradient-overlay" />
    </CustomScrollbar>
  );
}
