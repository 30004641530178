import React, { FC, JSX } from "react";
import { ReactComponent as LogoSvg } from "../../assets/icons/logo.svg";
import { useNavigate, NavigateFunction, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import {getHolidayFailure, getHolidaysMediaFailure} from "../../actions/holidays.actions";
import "./Footer.css";

interface IFooterProps {}

const Footer: FC<IFooterProps> = (): JSX.Element => {
  const navigate: NavigateFunction = useNavigate();

  const dispatch = useDispatch<AppDispatch>();

  const goToMainPage = (): void => {
    navigate("/");
    dispatch(getHolidayFailure());
    dispatch(getHolidaysMediaFailure());
  };

  return (
    <footer  itemScope itemType="http://schema.org/WPFooter" className="footerWrap bg-white">
      <div className="text-xl">
        <Link
          to={"/"}
          className="flex items-center w-[190px] mb-[24px] cursor-pointer"
          onClick={goToMainPage}
        >
          <LogoSvg className="footerLogo" />
          <span className="fs-20px">
            Поздравлятор
          </span>
        </Link>
        <div className="footerDescr">
          Поздравлятор – бесплатные открытки и&nbsp;поздравления
        </div>
        <a
          itemProp="url"
          className="block"
          href="https://play.google.com/store/apps/details?id=ru.pozdrav.android"
        >
          <div className="footerGoogle cursor-pointer"/>
        </a>
      </div>
      <div className="footerLinksWrap">
        <div className="footerCharacter" />
        <div className="footerLinkWrap">
          <a
            rel="noreferrer"
            itemProp="url"
            className="footerLink"
            target="_blank"
            href="https://docs.google.com/document/d/1XL-9DXRFkPk0SZCcylAqYZS_bZXZ4p4R8LPDG-efY6Q/edit?usp=sharing"
          >
            Политика конфиденциальности
          </a>
          <a
            rel="noreferrer"
            itemProp="url"
            className="footerLink"
            target="_blank"
            href="https://docs.google.com/document/d/1ZFcF5cAmdxx5T9XEUhH1Iy8ksjl93CjvElxoFZ3Tjgs/edit?usp=sharing"
          >
            Оферта пользователя
          </a>
          <a
            rel="noreferrer"
            itemProp="email"
            className="footerLink"
            target="_blank"
            href="mailto:pozdravapp@gmail.com"
          >
            Поддержка
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
