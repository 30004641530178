import axios from "../utils/axios";

const API_BASE: string | undefined = process.env.REACT_APP_API_BASE_URL;
const DEVICE_ID: string | undefined = process.env.REACT_APP_API_DEVICE_ID;

// Ручка получения праздника
export const getHoliday = async (holidayId: number): Promise<any> => {
  return axios.GET(API_BASE, `holidays/?user=${DEVICE_ID}&holiday=${holidayId}`);
};

// Ручка получения медиафайлов праздника
export const getHolidayMedia = async (holidayId: number, mediaType: string = "image"): Promise<any> => {
  return axios.GET(
    API_BASE,
    `holidays/holiday_media/?user=${DEVICE_ID}&holiday=${holidayId}&media_type=${mediaType}`
  );
};

// Ручка получения типов медиафайлов праздника
export const getHolidayMediaTypes = async (holidayId: number): Promise<any> => {
  return axios.GET(
    API_BASE,
    `holidays/holiday_media_types/?user=${DEVICE_ID}&holiday=${holidayId}&media_type=image`
  );
};

// Ручка получения категорий праздника
export const getHolidayCategories = async (): Promise<any> => {
  return axios.GET(
    API_BASE,
    `holidays/holiday_categories/?user=${DEVICE_ID}`
  );
};

// Ручка получения списка праздников с сортировкой по месяцам
export const getHolidaysListMonthSort = async (holidayName?: string): Promise<any> => {
  return axios.GET(
    API_BASE,
    `holidays/v2/list/?user=${DEVICE_ID}${holidayName ? `&holiday_name=${holidayName}` : ''}`
  );
};

// Ручка получения списка праздников без сортировки
export const getHolidaysList = async (): Promise<any> => {
  return axios.GET(
    API_BASE,
    `holidays/list/?user=${DEVICE_ID}&exclude_holidays_without_dates=true&exclude_daily_holidays=false&exclude_core_holidays=true`
  );
};
