import {
  FETCH_USER_INFO_FAILURE,
  FETCH_USER_INFO_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT,
} from "../constants/actionTypes";
import { AccountActionsTypes } from "../actions/user.actions";
import { UserInfoType } from "app/types";

const token: string | null = window?.localStorage.getItem("authToken");

export type InitialAccountStateType = {
  error: string | null;
  userData: UserInfoType | null;
  authToken: string | null;
  confirmNewPasswordStatus: number,
  isFetching: boolean;
};

const initialState: InitialAccountStateType = {
  error: null,
  userData: null,
  authToken: token,
  confirmNewPasswordStatus: 0,
  isFetching: true
};

const user = (
  state: InitialAccountStateType = initialState,
  action: AccountActionsTypes
): InitialAccountStateType => {
  switch (action.type) {
    // Логика деавторизации
    case LOGOUT:
      return {
        ...state,
        authToken: null,
        error: null
      };
    // Логика авторизации
    case LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        authToken: action.payload.token,
        error: null
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isFetching: false,
        authToken: null,
        error: action.payload.error
      };
    // Логика получения информации об авторизованном пользователе
    case FETCH_USER_INFO_SUCCESS:
      return {
        ...state,
        isFetching: false,
        userData: action.payload.userInfo,
        error: null
      };
    case FETCH_USER_INFO_FAILURE:
      return {
        ...state,
        isFetching: false,
        userData: null,
        error: action.payload.error
      };
    default:
      return state;
  }
};

export default user;
