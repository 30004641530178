import React, {
  FC,
  JSX,
  useState,
  RefObject,
  useEffect,
  useCallback,
} from "react";
import { useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../reducers/mainReducer";
import { AppDispatch } from "../../store/store";
import { HolidayMediaFileType } from "app/types";
import { getHolidayData, getHolidayMediaImg } from "../../actions/holidays.actions";
import { BreadCrumbs } from "../../utils/BreadCrumbs/BreadCrumbs";
import { formatDate } from "../../utils/formatDate";
import copy from "copy-to-clipboard";
import "./HolidayCard.css";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";

interface IHolidayCardProps {
  holidayCardRef: RefObject<HTMLDivElement>;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert  elevation={6} ref={ref} variant="filled" {...props} />;
});

const API_BASE: string | undefined = process.env.REACT_APP_API_BASE_URL;

export const HolidayCard: FC<IHolidayCardProps> = ({ holidayCardRef }) => {
  const [holidayId, setHolidayId] = useState<number>(0);
  const [alert, setAlert] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const dispatch = useDispatch<AppDispatch>();

  const pathname: string = useLocation().pathname;

  const { holidayData, holidayFilesMediaList } = useSelector((state: AppStateType) => state.holidays);

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 200);
  }, []);

  useEffect(() => {
    const holidayUrl: string[] = pathname?.split("holiday");
    const holidayId: string = holidayUrl?.[1]?.split("/")?.[1];

    if (holidayId) {
      setHolidayId(Number(holidayId));
      dispatch(getHolidayData(Number(holidayId)));
      dispatch(getHolidayMediaImg(Number(holidayId), "image", 0));
    }
  }, [pathname]);

  const handleCopyToClipboard = async (): Promise<void> => {
    const currentUrl: string = window.location.href;

    const shareData = {
      title: "Поздравлятор",
      url: currentUrl,
    };

    await navigator.share(shareData);

    //copy(currentUrl);
    //setAlert(true);
  };

  const renderHolidayPostcards = useCallback((): JSX.Element[] | undefined => {
    return holidayFilesMediaList?.map(({
      id,
      alt,
      media_file,
    }: HolidayMediaFileType, index: number) => (
      <div itemProp="image" className="holidaysPostcardImageWrap">
        {media_file ? <><Link
          itemProp="url"
          to={`/holiday/${holidayId}/postcard/${id}`}
          key={index}
          className={`holidaysPostcardImage ${alt === null || alt === "" ? "mb-[35px]" : "mb-[10px]"}`}
          style={{
            borderRadius: "10px",
            backgroundSize: "cover",
            backgroundImage: `url(${API_BASE}/${media_file})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top",
          }}
        />
          <span
            itemProp="description"
            content={alt}
            className="holidaysPostcardDescr"
          >
          {alt}
        </span></> : <CircularProgress sx={{color: "#FFD25C"}} size={60}/>}

      </div>
    ));
  }, [holidayFilesMediaList, holidayId]);

  return (!isLoading ? (
    <div
      itemProp="name"
      content={holidayData?.name}
      className={holidayFilesMediaList?.length < 6 ? "holidayCardWrap h-[1000px]" : "holidayCardWrap"}
      ref={holidayCardRef}
    >
      {!!holidayData?.name && (
        <>
          <BreadCrumbs
            className="mb-[37px]"
            holidayName={holidayData?.name}
            isPostcard={false}
            onClick={handleCopyToClipboard}
          />
          <h1 itemProp="name" content={holidayData?.name} className="holidayCardHeader">
            {holidayData?.name}
          </h1>
        </>
      )}
      {!!holidayData?.date && (
        <span className={`holidayCardDate ${holidayData?.description ? "mb-[10px]" : "mb-[40px]"}`}>
          {formatDate(holidayData?.date)}
        </span>
      )}
      {!!holidayData?.description && (
        <span itemProp="description" content={holidayData?.description} className="holidayCardDescr">
            {holidayData?.description}
        </span>
      )}
      <div itemProp="image" className="holidaysPostcardWrap">
        {renderHolidayPostcards()}
      </div>
      <Snackbar
        open={alert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={2000}
        onClose={() => setAlert(false)}
        message="Ссылка скопирована!"
      >
        <Alert sx={{ backgroundColor: "#ffd15c" }} severity="success">Ссылка скопирована</Alert>
      </Snackbar>
    </div>
  ) : (
    <div className="flex items-center justify-center h-[500px]">
      <CircularProgress sx={{color: "#FFD25C"}} size={100}/>
    </div>
  ));
};
