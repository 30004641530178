import React, {FC, useEffect, useState} from "react";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import "../HolidaysList.css";

const API_BASE: string | undefined = process.env.REACT_APP_API_BASE_URL;

interface IHolidayLinkProps {
  id: number;
  holidayId: number;
  media_file: string;
}

export const HolidayLink: FC<IHolidayLinkProps> = ({
    id,
    holidayId,
    media_file
  }) => {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`${API_BASE}/${media_file}`).then((res) => res.status === 200 ? setLoading(false) : setLoading(true))
  }, [media_file]);

  return (
    <>
      {isLoading ? (
        <CircularProgress sx={{color: "#FFD25C"}} size={60}/>
      ) : (
        <Link
          itemProp="url"
          content={media_file}
          to={`holiday/${holidayId}/postcard/${id}`}
          className="holidaysImageBlock"
          style={{
            borderRadius: "10px",
            backgroundSize: "cover",
            backgroundImage: `url(${API_BASE}/${media_file})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top",
          }}
        />
      )}
    </>
  );
};
