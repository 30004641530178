// Account actions 
export const LOGOUT = "LOGOUT";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const FETCH_USER_INFO_SUCCESS = "FETCH_USER_INFO_SUCCESS";
export const FETCH_USER_INFO_FAILURE = "FETCH_USER_INFO_FAILURE";

// Holidays actions 
export const GET_HOLIDAY_SUCCESS = "GET_HOLIDAY_SUCCESS";
export const GET_HOLIDAY_FAILURE = "GET_HOLIDAY_FAILURE";
export const GET_HOLIDAY_MEDIA_SUCCESS = "GET_HOLIDAY_MEDIA_SUCCESS";
export const GET_HOLIDAY_MEDIA_FAILURE = "GET_HOLIDAY_MEDIA_FAILURE";
export const GET_FIRST_HOLIDAY_MEDIA_SUCCESS = "GET_FIRST_HOLIDAY_MEDIA_SUCCESS";
export const GET_FIRST_HOLIDAY_MEDIA_FAILURE = "GET_FIRST_HOLIDAY_MEDIA_FAILURE";
export const GET_SECOND_HOLIDAY_MEDIA_SUCCESS = "GET_SECOND_HOLIDAY_MEDIA_SUCCESS";
export const GET_SECOND_HOLIDAY_MEDIA_FAILURE = "GET_SECOND_HOLIDAY_MEDIA_FAILURE";
export const GET_THIRD_HOLIDAY_MEDIA_SUCCESS = "GET_THIRD_HOLIDAY_MEDIA_SUCCESS";
export const GET_THIRD_HOLIDAY_MEDIA_FAILURE = "GET_THIRD_HOLIDAY_MEDIA_FAILURE";
export const GET_FOURTH_HOLIDAY_MEDIA_SUCCESS = "GET_FOURTH_HOLIDAY_MEDIA_SUCCESS";
export const GET_FOURTH_HOLIDAY_MEDIA_FAILURE = "GET_FOURTH_HOLIDAY_MEDIA_FAILURE";
export const GET_HOLIDAY_MEDIA_TYPES_SUCCESS = "GET_HOLIDAY_MEDIA_TYPES_SUCCESS";
export const GET_HOLIDAY_MEDIA_TYPES_FAILURE = "GET_HOLIDAY_MEDIA_TYPES_FAILURE";
export const GET_HOLIDAY_CATEGORIES_SUCCESS = "GET_HOLIDAY_CATEGORIES_SUCCESS";
export const GET_HOLIDAY_CATEGORIES_FAILURE = "GET_HOLIDAY_CATEGORIES_FAILURE";
export const GET_HOLIDAYS_LIST_SORT_SUCCESS = "GET_HOLIDAYS_LIST_SORT_SUCCESS";
export const GET_HOLIDAYS_LIST_SORT_FAILURE = "GET_HOLIDAYS_LIST_SORT_FAILURE";
export const GET_HOLIDAYS_LIST_SUCCESS = "GET_HOLIDAYS_LIST_SUCCESS";
export const GET_HOLIDAYS_LIST_FAILURE = "GET_HOLIDAYS_LIST_FAILURE";
