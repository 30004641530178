import React, {FC, JSX, RefObject, useCallback, useEffect, useRef, useState,} from "react";
import {Link, NavigateFunction, useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "../../store/store";
import {AppStateType} from "../../reducers/mainReducer";
import {getHolidayData, getHolidayMediaImg,} from "../../actions/holidays.actions";
import {BreadCrumbs} from "../../utils/BreadCrumbs/BreadCrumbs";
import {formatDate} from "../../utils/formatDate";
import {Swiper, SwiperSlide} from "swiper/react";
import {A11y, Navigation} from "swiper/modules";
import {Swiper as SwiperType} from "swiper";
import {HolidayMediaFileType} from "app/types";
import {CustomBtn} from "../../utils/CustomBtn/CustomBtn";
import copy from "copy-to-clipboard";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, {AlertProps} from "@mui/material/Alert";
import "./HolidayPostcard.css";
import CircularProgress from "@mui/material/CircularProgress";

interface IHolidayPostcardProps {
  holidayPostCardRef: RefObject<HTMLDivElement>;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const API_BASE: string | undefined = process.env.REACT_APP_API_BASE_URL;

export const HolidayPostcard: FC<IHolidayPostcardProps> = ({holidayPostCardRef}) => {
  const [currentFile, setCurrentFile] = useState<HolidayMediaFileType>();
  const [currentHolidayId, setCurrentHolidayId] = useState<number>(0);
  const [holidaysMediaFiles, setHolidaysMediaFiles] = useState<HolidayMediaFileType[]>([]);
  const [alert, setAlert] = useState<boolean>(false);
  const [isMediaFileUpdated, setMediaFileUpdated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [customBtnWidth, setCustomBtnWidth] = useState<string>("600px");
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [hideRecommendBlock, setHideRecommendBlock] = useState<boolean>(false);
  const [recommendedHolidaysMediaFiled, setRecommendedHolidaysMediaFiled] = useState<HolidayMediaFileType[]>([]);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = (): void => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return (): void => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth > 1430) setCustomBtnWidth("299px");
    if (windowWidth <= 1430) setCustomBtnWidth("274px");
    if (windowWidth <= 1250) setCustomBtnWidth("249px");
    if (windowWidth <= 1100) setCustomBtnWidth("224px");
    if (windowWidth <= 1000) {
      setHideRecommendBlock(true);
    } else setHideRecommendBlock(false);
    if (windowWidth <= 950) setCustomBtnWidth("90%");
    if (windowWidth <= 400) {
      setIsMobile(true);
    } else setIsMobile(false);
  }, [windowWidth]);

  useEffect(() => {
    if (windowWidth > 1000) {
      const slicedFiles: HolidayMediaFileType[] = holidaysMediaFiles?.slice(0, 3);
      setRecommendedHolidaysMediaFiled(slicedFiles);
    }

    if (windowWidth <= 1000) {
      const slicedFiles: HolidayMediaFileType[] = holidaysMediaFiles?.slice(0, 2);
      setRecommendedHolidaysMediaFiled(slicedFiles);
    }

    if (windowWidth <= 950) {
      const slicedFiles: HolidayMediaFileType[] = holidaysMediaFiles?.slice(0, 5);
      setRecommendedHolidaysMediaFiled(slicedFiles);
    }

    if (windowWidth <= 900) {
      const slicedFiles: HolidayMediaFileType[] = holidaysMediaFiles?.slice(0, 4);
      setRecommendedHolidaysMediaFiled(slicedFiles);
    }

    if (windowWidth <= 730) {
      const slicedFiles: HolidayMediaFileType[] = holidaysMediaFiles?.slice(0, 3);
      setRecommendedHolidaysMediaFiled(slicedFiles);
    }

    if (windowWidth <= 550) {
      const slicedFiles: HolidayMediaFileType[] = holidaysMediaFiles?.slice(0, 2);
      setRecommendedHolidaysMediaFiled(slicedFiles);
    }

    if (windowWidth <= 400) {
      setIsMobile(true);
    } else setIsMobile(false);
  }, [windowWidth, holidaysMediaFiles]);

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 200);
  }, []);

  const handleCopyToClipboard = async (): Promise<void> => {
    const currentUrl: string = window.location.href;

    const shareData = {
      title: "Поздравлятор",
      url: currentUrl,
    };

    await navigator.share(shareData);

    //copy(currentUrl);
    //setAlert(true);
  };

  const swiperRef = useRef<SwiperType>();

  const dispatch = useDispatch<AppDispatch>();

  const pathname: string = useLocation().pathname;

  const navigate: NavigateFunction = useNavigate();

  const {holidayData, holidayFilesMediaList} = useSelector((state: AppStateType) => state.holidays);

  useEffect(() => {
    const holidayUrl: string[] = pathname?.split("holiday");
    const holidayId: string = holidayUrl?.[1]?.split("/")?.[1];

    if (holidayId) {
      setCurrentHolidayId(Number(holidayId));
      dispatch(getHolidayData(Number(holidayId)));
      dispatch(getHolidayMediaImg(Number(holidayId), "image", 0));
    }
  }, [pathname]);

  useEffect(() => {
    if (holidayFilesMediaList?.length || isMediaFileUpdated) {
      const currentImgIndex: number = holidayFilesMediaList?.findIndex((image: HolidayMediaFileType): boolean =>
        image.id === Number(pathname?.split("/")?.[4]));

      setMediaFileUpdated(false);
      setCurrentFile(holidayFilesMediaList?.[currentImgIndex])
      setCurrentImgIndex(currentImgIndex);
      setHolidaysMediaFiles(holidayFilesMediaList);
    }
  }, [holidayFilesMediaList, pathname, isMediaFileUpdated]);

  const findCurrentImgIndex = (imageId: number) => {
    const currentImgIndex: number = holidayFilesMediaList?.findIndex((image: HolidayMediaFileType): boolean =>
      image.id === imageId);

    setMediaFileUpdated(true);
    setCurrentImgIndex(currentImgIndex);
  };

  const currentSlideIndex: number = holidayFilesMediaList?.findIndex((image: HolidayMediaFileType): boolean =>
    image.id === Number(pathname?.split("/")?.[4]));

  const [currentImgIndex, setCurrentImgIndex] = useState<number>(currentSlideIndex);

  const renderHolidayItemImg = useCallback((): JSX.Element[] => {
    return holidaysMediaFiles?.map(({
      id,
      media_file,
    }: HolidayMediaFileType) => (
      <SwiperSlide itemProp="iamge" className="overflow-hidden" key={id}>
        <a
          itemProp="iamge"
          className="postcardImage"
          href={`${API_BASE}/${media_file}`}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "10px",
            backgroundSize: "contain",
            backgroundImage: !isMobile ? "" : `url(${API_BASE}/${media_file})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          <img
            alt="Изображение открытки"
            style={isMobile ? {display: "none"} : { display: "block", width: "100%", height: "100%", left: "-3px"}}
            className="postcardImage"
            src={`${API_BASE}/${media_file}`}
            loading="lazy"
          />
        </a>
      </SwiperSlide>
    ));
  }, [holidaysMediaFiles, isMobile]);

  const prevSlide = (): void => {
    swiperRef.current?.slidePrev();
    const currentSlideId: number = holidaysMediaFiles?.[swiperRef?.current?.activeIndex as number]?.id;
    navigate(`/holiday/${currentHolidayId}/postcard/${currentSlideId}`);
  };

  const nextSlide = (): void => {
    swiperRef.current?.slideNext();
    const currentSlideId: number = holidaysMediaFiles?.[swiperRef?.current?.activeIndex as number]?.id;
    navigate(`/holiday/${currentHolidayId}/postcard/${currentSlideId}`);
  };

  const download = (filename?: string, url?: string): void => {
    // Создаем новый XMLHttpRequest
    const xhr: XMLHttpRequest = new XMLHttpRequest();
    xhr.open("GET", `${API_BASE}${url}`, true);
    xhr.responseType = "blob"; // Устанавливаем тип ответа как Blob

    // Когда запрос завершен
    xhr.onload = (): void => {
      // Создаем blob URL для изображения
      // @ts-ignore
      const blob: Blob = new Blob([xhr.response], {type: xhr.getResponseHeader("Content-Type")});
      const blobUrl: string = window.URL.createObjectURL(blob);

      // Создаем ссылку для скачивания
      const link: HTMLAnchorElement = document.createElement('a');
      link.href = blobUrl;
      link.download = filename as string;
      link.target = "_blank"

      // Добавляем ссылку в body, эмулируем клик и удаляем ссылку
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Очищаем blob URL после скачивания
      window.URL.revokeObjectURL(blobUrl);
    };

    // Отправляем запрос
    xhr.send();
  };

  useEffect(() => {
    const handleKeyPress = (event: { key: any; }) => {
      // Здесь вы можете выполнить любую логику в зависимости от нажатой клавиши
      if (event.key === "ArrowLeft") {
        return prevSlide();
      }

      if (event.key === "ArrowRight") {
        return nextSlide();
      }

      return null;
    };

    // Добавляем обработчик события при монтировании компонента
    window.addEventListener('keydown', handleKeyPress);

    // Очищаем обработчик события при размонтировании компонента
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [currentHolidayId, swiperRef]);

  return (!isLoading ? (
    <div
      itemProp="name"
      content={holidayData?.name}
      className="postcardWrap"
      ref={holidayPostCardRef}
    >
      {!!holidayData?.name && (
        <>
          <BreadCrumbs
            className="mb-[37px]"
            holidayName={holidayData?.name}
            isPostcard={true}
            onClick={handleCopyToClipboard}
          />
          <h1 itemProp="name" content={holidayData?.name} className="holidayCardHeader">
            {holidayData?.name}
          </h1>
        </>
      )}
      {!!holidayData?.date && (
        <span className="holidayPostCardDate">
          {formatDate(holidayData?.date)}
        </span>
      )}
      {!!holidaysMediaFiles?.length && (
        <div className="holidayPostCardSwiper">
          {!isMediaFileUpdated && (
            <Swiper
              slidesPerView={1}
              initialSlide={currentImgIndex}
              modules={[Navigation, A11y]}
              onBeforeInit={(swiper) => {
                swiperRef.current = swiper;
              }}
            >
              {renderHolidayItemImg()}
            </Swiper>
          )}
          <button
            className="prevSlideBtn"
            onClick={prevSlide}
          />
          <button
            className="nextSlideBtn"
            onClick={nextSlide}
          />
        </div>
      )}
      <div className="flex items-center justify-center mb-[50px]">
        <div className={`flex justify-between ${windowWidth <= 630 ? "flex-col" : ""}`}>
          <CustomBtn
            text="Скачать"
            width={customBtnWidth}
            marginBottom={windowWidth <= 630 ? "10px" : ""}
            marginRight="10px"
            isPostcard
            onClick={() => download(currentFile?.media_file?.split("/")?.[3], currentFile?.media_file)}
          />
          <CustomBtn
            text="Отправить"
            width={customBtnWidth}
            isPostcard
            onClick={async () => {
              const shareData = {
              title: currentFile?.media_file?.split("/")?.[3],
              url: currentFile?.media_file,
            };

              await navigator.share(shareData);
            }}
          />
        </div>
      </div>
      <div>
        <h2 itemProp="name" content="Рекомендуем" className="holidayPostCardRecommend">
          Рекомендуем
        </h2>
        <div className="holidayPostCardRecommendBlock">
          {!!recommendedHolidaysMediaFiled?.length && (
            recommendedHolidaysMediaFiled?.map(({id, media_file, alt}) => (
              <Link itemProp="url" to={`/holiday/${currentHolidayId}/postcard/${id}`}>
                <div
                  itemProp="image"
                  className="holidayPostCardRecommendImg"
                  onClick={() => findCurrentImgIndex(id)}
                  style={{
                    borderRadius: "10px",
                    backgroundSize: "cover",
                    backgroundImage: `url(${API_BASE}/${media_file})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                />
                <span
                  itemProp="description"
                  content={alt}
                  className={`holidayPostCardDescr`}
                >
                  {alt}
                </span>
              </Link>
            ))
          )}
        </div>
      </div>
      <Snackbar
        open={alert}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        autoHideDuration={2000}
        onClose={() => setAlert(false)}
        message="Ссылка скопирована!"
      >
        <Alert sx={{backgroundColor: "#ffd15c"}} severity="success">Ссылка скопирована</Alert>
      </Snackbar>
    </div>
  ) : (
    <div className="flex items-center justify-center h-[500px]">
      <CircularProgress sx={{color: "#FFD25C"}} size={100}/>
    </div>
  ));
};
