import axios, { Canceler, CancelTokenStatic } from "axios";

const token: string | null = window?.localStorage.getItem("authToken");

if (token !== null && token.length) {
  axios.defaults.headers.common.Authorization = JSON.parse(token);
}

export const setupResponseInterceptor = (): void => {
  axios.interceptors.response.use(null, (error) => {

    if (error.response && error.response.status === 401) {
      //store.dispatch(logout());
    }

    return Promise.reject(error);
  });
};

const CancelToken: CancelTokenStatic = axios.CancelToken;
export let cancel: Canceler;

const get = (host: string | undefined, path: string, params?: any) => {
  return axios({
    headers: {
      "Content-Type": "application/json"
    },
    method: "get",
    url: `${host}/${path}`,
    params,
    cancelToken: new CancelToken(function executor(canceler: Canceler) {
      cancel = canceler;
    })
  });
};

const put = (host: string | undefined, path: string, body?: any) => {
  return axios({
    headers: {
      "Content-Type": "application/json"
    },
    method: "put",
    url: `${host}/${path}`,
    data: body ? JSON.stringify(body) : undefined
  });
};

const post = (host: string | undefined, path: string, body?: any) => {
  return axios({
    headers: {
      "Content-Type": "application/json"
    },
    method: "post",
    url: `${host}/${path}`,
    data: body ? JSON.stringify(body) : undefined
  });
};

const patch = (host: string | undefined, path: string, body?: any) => {
  return axios({
    headers: {
      "Content-Type": "application/json"
    },
    method: "patch",
    url: `${host}/${path}`,
    data: body ? JSON.stringify(body) : undefined
  });
};

const deleted = (host: string | undefined, path: string) => {
  return axios({
    headers: {
      "Content-Type": "application/json"
    },
    method: "delete",
    url: `${host}/${path}`
  });
};

export default {
  GET: get,
  PUT: put,
  POST: post,
  PATCH: patch,
  DELETE: deleted,
};
