import {ThunkAction} from "redux-thunk";
import {AppStateType} from "../reducers/mainReducer";
import {
  GET_FIRST_HOLIDAY_MEDIA_FAILURE,
  GET_FIRST_HOLIDAY_MEDIA_SUCCESS,
  GET_FOURTH_HOLIDAY_MEDIA_FAILURE,
  GET_FOURTH_HOLIDAY_MEDIA_SUCCESS,
  GET_HOLIDAY_FAILURE,
  GET_HOLIDAY_MEDIA_FAILURE,
  GET_HOLIDAY_MEDIA_SUCCESS,
  GET_HOLIDAY_SUCCESS,
  GET_HOLIDAYS_LIST_FAILURE,
  GET_HOLIDAYS_LIST_SORT_FAILURE,
  GET_HOLIDAYS_LIST_SORT_SUCCESS,
  GET_HOLIDAYS_LIST_SUCCESS,
  GET_SECOND_HOLIDAY_MEDIA_FAILURE,
  GET_SECOND_HOLIDAY_MEDIA_SUCCESS,
  GET_THIRD_HOLIDAY_MEDIA_FAILURE,
  GET_THIRD_HOLIDAY_MEDIA_SUCCESS,
} from "../constants/actionTypes";
import * as HolidaysAPI from "../api/holidays.api";
import {HolidayMediaFilesListType, HolidaysListSortType, HolidaysListType, HolidayType,} from "app/types";

export type HolidaysActionsTypes =
  | GetHolidaysListSortSuccessType
  | GetHolidaysListSortFailureType
  | GetHolidaysListSuccessType
  | GetHolidaysListFailureType
  | GetHolidaysMediaSuccessType
  | GetHolidaysMediaFailureType
  | GetFirstHolidaysMediaSuccessType
  | GetFirstHolidaysMediaFailureType
  | GetSecondHolidaysMediaSuccessType
  | GetSecondHolidaysMediaFailureType
  | GetThirdHolidaysMediaSuccessType
  | GetThirdHolidaysMediaFailureType
  | GetFourthHolidaysMediaSuccessType
  | GetFourthHolidaysMediaFailureType
  | GetHolidaySuccessType
  | GetHolidayFailureType;

// Логика получения списка праздников с сортировкой по месяцам
type GetHolidaysListSortSuccessType = {
  type: typeof GET_HOLIDAYS_LIST_SORT_SUCCESS;
  payload: {
    holidaysSortList: HolidaysListSortType;
  };
};

export const getHolidaysSortSuccess = (holidaysSortList: HolidaysListSortType): GetHolidaysListSortSuccessType => ({
  type: GET_HOLIDAYS_LIST_SORT_SUCCESS,
  payload: {
    holidaysSortList
  }
});

type GetHolidaysListSortFailureType = {
  type: typeof GET_HOLIDAYS_LIST_SORT_FAILURE;
  payload: {
    error: string;
  };
};

export const getHolidaysSortFailure = (error: string): GetHolidaysListSortFailureType => ({
  type: GET_HOLIDAYS_LIST_SORT_FAILURE,
  payload: {
    error
  }
});

export const getHolidaysSortList = (holidayName?: string):
    ThunkAction<
    Promise<void>,
    AppStateType,
    undefined,
    HolidaysActionsTypes
  > => async (dispatch): Promise<void> => {
  try {
    const response = await HolidaysAPI.getHolidaysListMonthSort(holidayName);

    dispatch(getHolidaysSortSuccess(response.data));
  } catch (error: any) {
    dispatch(getHolidaysSortFailure(error.message));
  }
};

// Логика получения списка праздников без сортировки
type GetHolidaysListSuccessType = {
  type: typeof GET_HOLIDAYS_LIST_SUCCESS;
  payload: {
    holidaysList: HolidaysListType;
  };
};

export const getHolidaysListSuccess = (holidaysList: HolidaysListType): GetHolidaysListSuccessType => ({
  type: GET_HOLIDAYS_LIST_SUCCESS,
  payload: {
    holidaysList
  }
});

type GetHolidaysListFailureType = {
  type: typeof GET_HOLIDAYS_LIST_FAILURE;
  payload: {
    error: string;
  };
};

export const getHolidaysListFailure = (error: string): GetHolidaysListFailureType => ({
  type: GET_HOLIDAYS_LIST_FAILURE,
  payload: {
    error
  }
});

export const getHolidaysListData = ():
    ThunkAction<
    Promise<void>,
    AppStateType,
    undefined,
    HolidaysActionsTypes
  > => async (dispatch): Promise<void> => {
  try {
    const response = await HolidaysAPI.getHolidaysList();

    dispatch(getHolidaysListSuccess(response.data));
  } catch (error: any) {
    dispatch(getHolidaysListFailure(error.message));
  }
};

// Логика получения медиа файлов праздника
type GetHolidaysMediaSuccessType = {
  type: typeof GET_HOLIDAY_MEDIA_SUCCESS;
  payload: {
    holidayFilesMediaList: HolidayMediaFilesListType;
  };
};

export const getHolidaysMediaSuccess = (holidayFilesMediaList: HolidayMediaFilesListType): GetHolidaysMediaSuccessType => ({
  type: GET_HOLIDAY_MEDIA_SUCCESS,
  payload: {
    holidayFilesMediaList
  }
});

type GetHolidaysMediaFailureType = {
  type: typeof GET_HOLIDAY_MEDIA_FAILURE;
  payload: {
    error: string | undefined ;
  };
};

export const getHolidaysMediaFailure = (error?: string | undefined): GetHolidaysMediaFailureType => ({
  type: GET_HOLIDAY_MEDIA_FAILURE,
  payload: {
    error
  }
});

type GetFirstHolidaysMediaSuccessType = {
  type: typeof GET_FIRST_HOLIDAY_MEDIA_SUCCESS;
  payload: {
    firstHolidayFilesMediaList: HolidayMediaFilesListType;
  };
};

export const getFirstHolidaysMediaSuccess = (firstHolidayFilesMediaList: HolidayMediaFilesListType): GetFirstHolidaysMediaSuccessType => ({
  type: GET_FIRST_HOLIDAY_MEDIA_SUCCESS,
  payload: {
    firstHolidayFilesMediaList
  }
});

type GetFirstHolidaysMediaFailureType = {
  type: typeof GET_FIRST_HOLIDAY_MEDIA_FAILURE;
  payload: {
    error: string;
  };
};

export const getFirstHolidaysMediaFailure = (error: string): GetFirstHolidaysMediaFailureType => ({
  type: GET_FIRST_HOLIDAY_MEDIA_FAILURE,
  payload: {
    error
  }
});

type GetSecondHolidaysMediaSuccessType = {
  type: typeof GET_SECOND_HOLIDAY_MEDIA_SUCCESS;
  payload: {
    secondHolidayFilesMediaList: HolidayMediaFilesListType;
  };
};

export const getSecondHolidaysMediaSuccess = (secondHolidayFilesMediaList: HolidayMediaFilesListType): GetSecondHolidaysMediaSuccessType => ({
  type: GET_SECOND_HOLIDAY_MEDIA_SUCCESS,
  payload: {
    secondHolidayFilesMediaList
  }
});

type GetSecondHolidaysMediaFailureType = {
  type: typeof GET_SECOND_HOLIDAY_MEDIA_FAILURE;
  payload: {
    error: string;
  };
};

export const getSecondHolidaysMediaFailure = (error: string): GetSecondHolidaysMediaFailureType => ({
  type: GET_SECOND_HOLIDAY_MEDIA_FAILURE,
  payload: {
    error
  }
});

type GetThirdHolidaysMediaSuccessType = {
  type: typeof GET_THIRD_HOLIDAY_MEDIA_SUCCESS;
  payload: {
    thirdHolidayFilesMediaList: HolidayMediaFilesListType;
  };
};

export const getThirdHolidaysMediaSuccess = (thirdHolidayFilesMediaList: HolidayMediaFilesListType): GetThirdHolidaysMediaSuccessType => ({
  type: GET_THIRD_HOLIDAY_MEDIA_SUCCESS,
  payload: {
    thirdHolidayFilesMediaList
  }
});

type GetThirdHolidaysMediaFailureType = {
  type: typeof GET_THIRD_HOLIDAY_MEDIA_FAILURE;
  payload: {
    error: string;
  };
};

export const getThirdHolidaysMediaFailure = (error: string): GetThirdHolidaysMediaFailureType => ({
  type: GET_THIRD_HOLIDAY_MEDIA_FAILURE,
  payload: {
    error
  }
});

type GetFourthHolidaysMediaSuccessType = {
  type: typeof GET_FOURTH_HOLIDAY_MEDIA_SUCCESS;
  payload: {
    fourthHolidayFilesMediaList: HolidayMediaFilesListType;
  };
};

export const getFourthHolidaysMediaSuccess = (fourthHolidayFilesMediaList: HolidayMediaFilesListType): GetFourthHolidaysMediaSuccessType => ({
  type: GET_FOURTH_HOLIDAY_MEDIA_SUCCESS,
  payload: {
    fourthHolidayFilesMediaList
  }
});

type GetFourthHolidaysMediaFailureType = {
  type: typeof GET_FOURTH_HOLIDAY_MEDIA_FAILURE;
  payload: {
    error: string;
  };
};

export const getFourthHolidaysMediaFailure = (error: string): GetFourthHolidaysMediaFailureType => ({
  type: GET_FOURTH_HOLIDAY_MEDIA_FAILURE,
  payload: {
    error
  }
});

export const getHolidayMediaImg = (
  holidayId: number,
  mediaType?: string,
  holidayPosition: number = 0
): ThunkAction<
   Promise<void>,
   AppStateType,
   undefined,
   HolidaysActionsTypes
> => async (dispatch): Promise<void> => {
  try {
    const response = await HolidaysAPI.getHolidayMedia(holidayId, mediaType);

    switch (holidayPosition) {
      case 0:
        dispatch(getHolidaysMediaSuccess(response.data));
        break;
      case 1:
        dispatch(getFirstHolidaysMediaSuccess(response.data));
        break;
      case 2:
        dispatch(getSecondHolidaysMediaSuccess(response.data));
        break;
      case 3:
        dispatch(getThirdHolidaysMediaSuccess(response.data));
        break;
      case 4:
        dispatch(getFourthHolidaysMediaSuccess(response.data));
        break;
      default:
        dispatch(getHolidaysMediaSuccess(response.data));
        break;
    }
  } catch (error: any) {
    switch (holidayPosition) {
      case 0:
        dispatch(getHolidaysMediaFailure(error.message));
        break;
      case 1:
        dispatch(getFirstHolidaysMediaFailure(error.message));
        break;
      case 2:
        dispatch(getSecondHolidaysMediaFailure(error.message));
        break;
      case 3:
        dispatch(getThirdHolidaysMediaFailure(error.message));
        break;
      case 4:
        dispatch(getFourthHolidaysMediaFailure(error.message));
        break;
      default:
        dispatch(getHolidaysMediaFailure(error.message));
        break;
    }
  }
};

// Логика получения списка праздников без сортировки
type GetHolidaySuccessType = {
  type: typeof GET_HOLIDAY_SUCCESS;
  payload: {
    holidayData: HolidayType;
  };
};

export const getHolidaySuccess = (holidayData: HolidayType): GetHolidaySuccessType => ({
  type: GET_HOLIDAY_SUCCESS,
  payload: {
    holidayData
  }
});

type GetHolidayFailureType = {
  type: typeof GET_HOLIDAY_FAILURE;
  payload: {
    error: string | undefined;
  };
};

export const getHolidayFailure = (error?: string | undefined): GetHolidayFailureType => ({
  type: GET_HOLIDAY_FAILURE,
  payload: {
    error
  }
});

export const getHolidayData = (holidayId: number):
  ThunkAction<
    Promise<void>,
    AppStateType,
    undefined,
    HolidaysActionsTypes
  > => async (dispatch): Promise<void> => {
  try {
    const response = await HolidaysAPI.getHoliday(holidayId);

    dispatch(getHolidaySuccess(response.data));
  } catch (error: any) {
    dispatch(getHolidayFailure(error.message));
  }
};
