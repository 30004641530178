import React, {
  FC,
  JSX,
  useState,
  RefObject,
  useEffect,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store/store";
import { getHolidayMediaImg, getHolidaysListData } from "../../actions/holidays.actions";
import { HolidayMediaFileType, HolidaySortType } from "app/types";
import { AppStateType } from "../../reducers/mainReducer";
import { useNavigate, NavigateFunction, Link } from "react-router-dom";
import { formatDate } from "../../utils/formatDate";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "./HolidaysList.css";
import CircularProgress from "@mui/material/CircularProgress";
import {HolidaySlide} from "./HolidaySlide/HolidaySlide";
import {HolidayLink} from "./HolidayLink/HolidayLink";

const API_BASE: string | undefined = process.env.REACT_APP_API_BASE_URL;

interface IHolidaysListProps {
  holidayListRef: RefObject<HTMLDivElement>;
}

enum HolidayItem {
  First = 0,
  Second = 1,
  Third = 2,
  Fourth = 3,
}

export const HolidaysList: FC<IHolidaysListProps> = ({ holidayListRef }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [slidesPerView, setSlidesPerView] = useState<number>(6);
  const [sliderSpaceBetween, setSliderSpaceBetween] = useState<number>(20);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [isImageLoaded, setImageLoaded] = useState<boolean>(false);

  const {
    holidaysList,
    firstHolidayFilesMediaList,
    secondHolidayFilesMediaList,
    thirdHolidayFilesMediaList,
    fourthHolidayFilesMediaList,
  } = useSelector((state: AppStateType) => state.holidays);

  useEffect(() => {
    const handleResize = (): void => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return (): void => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth > 1250) setSlidesPerView(6);
    if (windowWidth > 1250) setSliderSpaceBetween(20);
    if (windowWidth <= 1249) setSlidesPerView(5);
    if (windowWidth <= 1249) setSliderSpaceBetween(10);
    if (windowWidth <= 950) setSlidesPerView(8);
    if (windowWidth <= 950) setSliderSpaceBetween(1);
    if (windowWidth <= 800) setSlidesPerView(6);
    if (windowWidth <= 630) setSlidesPerView(5);
    if (windowWidth <= 530) setSlidesPerView(4);
    if (windowWidth <= 430) setSlidesPerView(3);
  }, [windowWidth]);

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 200);
  }, []);

  const navigate: NavigateFunction = useNavigate();

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getHolidaysListData());
  }, [dispatch]);

  useEffect(() => {
    if (holidaysList[HolidayItem.First]?.id && !firstHolidayFilesMediaList?.length) {
      dispatch(getHolidayMediaImg(holidaysList[HolidayItem.First]?.id, "image", HolidayItem.First + 1));
    }

    if (holidaysList[HolidayItem.Second]?.id && !secondHolidayFilesMediaList?.length) {
      dispatch(getHolidayMediaImg(holidaysList[HolidayItem.Second]?.id, "image", HolidayItem.Second + 1));
    }

    if (holidaysList[HolidayItem.Third]?.id && !thirdHolidayFilesMediaList?.length) {
      dispatch(getHolidayMediaImg(holidaysList[HolidayItem.Third]?.id, "image", HolidayItem.Third + 1));
    }

    if (holidaysList[HolidayItem.Fourth]?.id && !fourthHolidayFilesMediaList?.length) {
      dispatch(getHolidayMediaImg(holidaysList[HolidayItem.Fourth]?.id, "image", HolidayItem.Fourth + 1));
    }
  }, [dispatch, holidaysList]);

  const openHolidayPostcardCard = (holidayId: number, postcardId: number): void => {
    navigate(`holiday/${holidayId}/postcard/${postcardId}`);
  };

  const isLoaded = async (url: string) => {
    let response = await fetch(url)

    return response.ok
  }

  const renderPopularHolidays = useCallback((): JSX.Element[] => {
    return holidaysList?.map(({
      id,
      name,
      title_image,
    }: HolidaySortType): JSX.Element => {
      return (
        <SwiperSlide
          itemScope
          itemType="http://schema.org/ImageObject"
          key={id}
          className="mr-[20px] cursor-pointer"
        >
          <Link to={`holiday/${id}`}>
            <HolidaySlide  name={name} title_image={title_image}/>
          </Link>
        </SwiperSlide>
      )
    });
  }, [holidaysList]);

  const renderHolidayItemImg = useCallback((
    holidayFilesMediaList: HolidayMediaFileType[],
    holidayId: number,
  ): JSX.Element[] => {
    return holidayFilesMediaList?.map(({
      id,
      media_file,
    }: HolidayMediaFileType) => (
      <SwiperSlide
        itemProp="itemListElement"
        itemType="http://schema.org/Product"
        key={id}
        onClick={() => openHolidayPostcardCard(holidayId, id)}
      >
        <HolidayLink id={id} holidayId={holidayId} media_file={media_file}/>
      </SwiperSlide>
    ));
  }, [
    firstHolidayFilesMediaList,
    secondHolidayFilesMediaList,
    thirdHolidayFilesMediaList,
    fourthHolidayFilesMediaList,
  ]);

  const renderHolidayItem = (holidayItem: number): JSX.Element => {
    return (
      <div className={`
      flex flex-col ${holidayItem === 0 ? "" : "mt-[-20px]"} ${holidayItem === 3 ? "mb-[-60px]" : ""}
      `}>
        {!!holidaysList[holidayItem]?.name && (
          <Link
            itemProp="url"
            to={`/holiday/${holidaysList[holidayItem]?.id}`}
          >
            <span className="holidayName" itemProp="name" content={holidaysList[holidayItem]?.name}>
              {holidaysList[holidayItem]?.name}
            </span>
          </Link>
        )}
        {!!holidaysList[holidayItem]?.date && (
          <span
            itemProp="date"
            content={holidaysList[holidayItem]?.date}
            className="holidayDate"
          >
            {formatDate(holidaysList[holidayItem]?.date)}
          </span>
        )}
        {!!holidaysList[holidayItem]?.description && (
          <span
            itemProp="description"
            className="holidayDescr"
            content={holidaysList[holidayItem]?.description}
          >
            {holidaysList[holidayItem]?.description}
          </span>
        )}
        <div className="holidaysPopularItemsBlock">
          <Swiper
            spaceBetween={sliderSpaceBetween}
            slidesPerView={slidesPerView}
            modules={[Navigation, A11y]}
            navigation
          >
            {renderHolidayItemImg(holidayItemFilePosition(holidayItem), holidaysList[holidayItem]?.id)}
          </Swiper>
          {renderHolidayItemImg(holidayItemFilePosition(holidayItem), holidaysList[holidayItem]?.id)?.length > 4 && (
            <div className="image-gradient-overlay"/>
          )}
        </div>
      </div>
    );
  };

  const holidayItemFilePosition = (holidayItemPosition: number): HolidayMediaFileType[] => {
    switch (holidayItemPosition) {
      case HolidayItem.First:
        return firstHolidayFilesMediaList;
      case HolidayItem.Second:
        return secondHolidayFilesMediaList;
      case HolidayItem.Third:
        return thirdHolidayFilesMediaList;
      case HolidayItem.Fourth:
        return fourthHolidayFilesMediaList;
      default:
        return firstHolidayFilesMediaList;
    }
  };

  return (!isLoading ? (
      <div
        itemProp="description"
        content="Открытки и поздравления с праздником"
        className="holidaysListWrap"
        ref={holidayListRef}
      >
        <h1 itemProp="name" content="Популярные" className="holidaysListHeader">
          Популярные
        </h1>
        <div className="holidaysPopularBlock">
          <Swiper
            spaceBetween={sliderSpaceBetween}
            slidesPerView={slidesPerView}
            modules={[Navigation, A11y]}
            navigation
          >
            {renderPopularHolidays()}
          </Swiper>
          <div className="popular-gradient-overlay"/>
        </div>
        <>
          {renderHolidayItem(HolidayItem.First)}
          {renderHolidayItem(HolidayItem.Second)}
          {renderHolidayItem(HolidayItem.Third)}
          {renderHolidayItem(HolidayItem.Fourth)}
        </>
      </div>
  ) : (
    <div className="flex items-center justify-center h-[500px]">
      <CircularProgress sx={{color: "#FFD25C"}} size={100}/>
    </div>
  ));
};
