import React from "react";
import "./CustomScrollbar.css";

const CustomScrollbar = ({ children }: any) => {
  return (
    <div className="custom-scrollbar relative">
      <div className="scroll-content">{children}</div>
    </div>
  );
};

export default CustomScrollbar;
